<template>
  <el-container class="statistics">
    <el-header>
      <public-header :leftArrow="false">
        <span>名师工作室</span>
        <div class="revisedBtn" @click="goBack">返回列表</div>
      </public-header>
    </el-header>
    <el-main>
      <v-card class="rounded-xl" @click="beginTest(item.id, trainSetId)">
        <color-title color="#00CEB5">名师工作室</color-title>
        <div class="tip">由名师团队设计的高质量校本作业，让每个学生按照自己的学习进度，建立稳固、系统的知识体系</div>
        <statisticsChart :value="chartValue"></statisticsChart>
      </v-card>
    </el-main>
  </el-container>
</template>
<script>
import statisticsChart from '../../components/teacherStudio/statisticsChart'
export default {
  components: {
    statisticsChart
  },
  data() {
    return {
      chartValue: {
        line: [],
        bar: []
      }
    }
  },
  mounted() {
    this.famousTeacherWeekStatistic()
  },
  methods: {
    famousTeacherWeekStatistic() {
      this.$service.famousTeacherWeekStatistic().then((res) => {
        console.log(res)
        let line = []
        res.virtualIndicator.map(i => {
          if (i !== '0') {
            line.push(Number(i))
          }
        })
        this.chartValue.line = line
        console.log(line)
        this.chartValue.bar = res.amountOfQuestion
      })
    },
    goBack(){
      this.$router.go(-1)
    }
  },
}
</script>
<style lang="scss" scoped>
.statistics {
  .revisedBtn {
    float: right;
    width: 120px;
    height: 40px;
    margin-top: 12px;
    background: #ffffff;
    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    color: #666;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  .el-main {
    position: relative;
  }

  .v-card {
    padding: 24px 32px;
    background-image: url(../../assets/images/teacherStudio/cardBg.png);
    background-size: 100% auto;
  }

  .tip {
    color: #333333;
    margin: 10px 0 30px;
  }
}
</style>