<template>
  <div id="chart" class="chart"> </div>
</template>

<script>
export default {
  computed: {
    changeMini() {
      return this.$store.state.mini
    },
  },
  watch: {
    changeMini() {
      let that = this
      setTimeout(function () {
        that.myChart.resize()
      }, 50)
    },
    value: {
      handler() {
        console.log("!!!")
        this.drawMyChart()
      },
      deep: true
    }
  },
  props: {
    value: Object
  },
  data() {
    return {
      myChart: null
    }
  },
  mounted() {
    this.initEcharts()
  },
  methods: {
    initEcharts() {
      this.myChart = this.echarts.init(document.getElementById('chart'))
      // this.drawMyChart()
    },
    drawMyChart() {
      var option = {
        legend: {
          left: 'right',
        },
        grid: {
          left: '5%',
          bottom: '5%'
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLine: {
            show: true,
            lineStyle: {
              color: '#F7F7F7'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#8D8D8D'
          }
        },
        yAxis: {
          type: 'value',
          name: "做题数",
          nameTextStyle: {
            color: '#8D8D8D',
            align: 'right',
            lineHeight: 60
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#F7F7F7'
            }
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            color: '#8D8D8D'
          }
        },
        series: [
          {
            name: '平均数',
            data: this.value.line,
            type: 'line',
            smooth: true,
            symbolSize: 8,
            lineStyle: {
              color: '#0148FF',
              width: 6
            }
          },
          {
            name: '个人',
            data: this.value.bar,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: '#eee',
              borderRadius: 12,
            },
            itemStyle: {
              color: '#00CEB5',
              borderRadius: 12,
            }
          }
        ]
      };
      option && this.myChart.setOption(option)
    },
  },
}
</script>
<style scoped lang="scss">
.chart {
  width: 100%;
  height: 400px;
  border-color: #fff;
}
</style>
